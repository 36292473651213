import React, { useState, useEffect } from "react";
import Hero404 from "../components/Hero404";
import Layout from "../components/layout";
import SEO from "../components/seo";

export default function NotFound({ pageContext, data, ...props }) {
	const [pageComponent, setPageComponent] = useState(null);

	useEffect(() => {
		(async () => {
			if (data && pageContext["entryType"]) {
				const templateNames = {
					trustCenter: "trustCenterSingle",
					landingPages: "landingPage",
					heroProducts: "heroProduct",
					helpSupport: "helpSingle",
					guides: "guidesSingle",
					calculators: "loanCalculator",
				};

				const template = await import(
					`./${templateNames[pageContext["entryType"]]}`
				);
				setPageComponent({ Template: template.default });
			}
		})();
	}, [pageContext, data]);

	if (pageComponent) {
		let newProps = { ...props, pageContext, data };
		return <pageComponent.Template {...newProps} />;
	} else {
		return (
			<Layout className="layout--notFound">
				<SEO title="Page not found" />

				<Hero404
					title="Oops"
					text="The page you're looking for doesn't seem to exist."
					cta={{
						url: "/",
						target: "",
						type: "",
						text: "Go to homepage",
						type: "entry",
					}}
					colorTheme="dark"
				/>
			</Layout>
		);
	}
}
