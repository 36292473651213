import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "@emotion/styled";
import { Para, Heading1 } from "./elements/Section";
import PropTypes from "prop-types";
import {
	container,
	gridColumnByMargin,
	spacingRestricted,
	screen,
	grid,
} from "../styles/mixins";
import Button from "./elements/Button";
import color, { themeColors } from "../styles/colors";
import FluidImagerX from "./elements/FluidImagerX";

const SectionEl = styled.div`
	${spacingRestricted("padding-top", "xxl")};
	position: relative;

	// theming
	background-color: ${(props) => themeColors[props.colorTheme].bg};
	color: ${(props) => themeColors[props.colorTheme].fg};
`;

const ContainerEl = styled.div`
	${container()}
`;

const HeroGenericEl = styled.div`
	${gridColumnByMargin(2)};
	text-align: center;
`;

const MainImage = styled(FluidImagerX)`
	max-width: 284px;
	max-height: 60vw;
	margin: 0 auto;
	${spacingRestricted("margin-bottom", "m")}

	@media ${screen("md")} {
		max-height: none;
	}
`;

const Title = styled(Heading1)`
	color: ${color("beige")};
`;

const TextWrapper = styled.div`
	@media ${screen("md")} {
		${grid(44)};
	}
`;

const ParaEl = styled(Para)`
	@media ${screen("md")} {
		${gridColumnByMargin(5, 44)};
	}
	@media ${screen("lg")} {
		${gridColumnByMargin(11, 44)};
	}
`;

const ButtonWrapper = styled.div`
	${spacingRestricted("margin-top", "m")}
`;

const Hero404 = ({ className, title, text, cta, colorTheme = "light" }) => {
	const {
		craft: { image },
	} = useStaticQuery(graphql`
		query {
			craft {
				image: asset(filename: "not-found.png") {
					width
					height
					url
					imagerX(transform: "maxWidth-1920") {
						width
						height
						url
					}
					imagerXWp: imagerX(transform: "maxWidth-1920-wp") {
						width
						height
						url
					}
				}
			}
		}
	`);

	return (
		<SectionEl colorTheme={colorTheme}>
			<ContainerEl>
				<HeroGenericEl className={className} colorTheme={colorTheme}>
					{image && (
						<MainImage
							image={image}
							sizes="184px"
							imgStyle={{ objectFit: "contain" }}
							loading="eager"
							gatsbyImage={false}
						/>
					)}
					{title && <Title medium={true}>{title}</Title>}
					{text && (
						<TextWrapper>
							<ParaEl>{text}</ParaEl>
						</TextWrapper>
					)}
					{cta?.url && (
						<ButtonWrapper>
							<Button
								url={cta.url}
								target={cta.target}
								linkType={cta.type}
								element={cta?.element}
								external={cta.type !== "entry"}
								data-event-name="select_cta"
								data-content-type="Go to known page"
								data-content-destination={cta.url}
								data-content-text={cta.text}>
								{cta.text}
							</Button>
						</ButtonWrapper>
					)}
				</HeroGenericEl>
			</ContainerEl>
		</SectionEl>
	);
};

Hero404.propTypes = {
	colorTheme: PropTypes.string,
};

Hero404.defaultProps = {
	colorTheme: "light",
};

export default Hero404;
